<template>
	<div class="switch flex">
		<div 
			v-for="option in options"
			class="option flex h-center v-center" 
			:class="{current : option.value == modelValue}"
			@click="select(option.value)"
			:title="locale[option.title]"
		>
			<div v-if="option.icon">
				<svg v-if="option.icon == 'unbounded_mode'" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 16.5C3.41153 16.5 3.76442 16.3529 4.05865 16.0587C4.35288 15.7644 4.5 15.4115 4.5 15C4.5 14.5885 4.35288 14.2356 4.05865 13.9413C3.76442 13.6471 3.41153 13.5 3 13.5C2.58847 13.5 2.23558 13.6471 1.94135 13.9413C1.64712 14.2356 1.5 14.5885 1.5 15C1.5 15.4115 1.64712 15.7644 1.94135 16.0587C2.23558 16.3529 2.58847 16.5 3 16.5ZM3 17.5C2.30768 17.5 1.71794 17.2564 1.23078 16.7692C0.743592 16.2821 0.5 15.6923 0.5 15C0.5 14.3077 0.743592 13.718 1.23078 13.2308C1.71794 12.7436 2.30768 12.5 3 12.5C3.59102 12.5 4.11506 12.6837 4.57213 13.051C5.02918 13.4183 5.31668 13.8936 5.43463 14.4769C6.21283 14.409 6.86698 14.0891 7.3971 13.5173C7.92723 12.9455 8.1923 12.2731 8.1923 11.5V7.78845C8.1923 6.54615 8.63172 5.48557 9.51057 4.60672C10.3894 3.72787 11.45 3.28845 12.6923 3.28845H14.5731L12.4981 1.21345L13.2115 0.5L16.5 3.78845L13.2115 7.07693L12.4981 6.38845L14.5539 4.28848H12.6923C11.718 4.28848 10.891 4.62822 10.2115 5.3077C9.53205 5.98718 9.1923 6.8141 9.1923 7.78845V11.5C9.1923 12.55 8.82948 13.4542 8.10385 14.2125C7.3782 14.9708 6.4923 15.3942 5.44615 15.4827C5.3359 16.0737 5.05032 16.5577 4.58943 16.9346C4.12853 17.3115 3.59872 17.5 3 17.5Z" fill="black"/></svg>
				<svg v-if="option.icon == 'bounded_mode'" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.846 0.497381V1.49738H15.1383L3.69775 12.9314L4.417 13.6506L15.846 2.20508V5.49739H16.846V0.497381H11.846Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.6543 16.5027C3.48272 16.5027 4.1543 15.8311 4.1543 15.0027C4.1543 14.1743 3.48272 13.5027 2.6543 13.5027C1.82587 13.5027 1.1543 14.1743 1.1543 15.0027C1.1543 15.8311 1.82587 16.5027 2.6543 16.5027ZM2.6543 17.5027C4.03501 17.5027 5.1543 16.3834 5.1543 15.0027C5.1543 13.622 4.03501 12.5027 2.6543 12.5027C1.27358 12.5027 0.154297 13.622 0.154297 15.0027C0.154297 16.3834 1.27358 17.5027 2.6543 17.5027Z" fill="black"/></svg>
			</div>
			
			<span v-if="option.text">{{ option.text }}</span>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	
	export default {
		name: 'Switch',
		props: ['modelValue', 'options'],
		emits: ['update:modelValue', 'change'],
		computed: {
			...mapState([
				'locale'
			]),
		},
		methods: {
			select(value){
				this.$emit('update:modelValue', value);
				this.$emit('change', value);
			}
		}
	}
</script>

<style scoped lang="scss">
	.switch{
		font-size: 16px;
		background: #EDEDED;
		border-radius: 24px;
		
		.option{
			border-radius: 24px;
			padding: 8px 24px;
			text-align: center;
			width: 100%;
			cursor: pointer;
			white-space: nowrap;
			gap: 10px;
			
			svg path{
				fill: black;
			}
			
			&:hover{
				background: #D4D4D4;
			}
			
			&.current{
				background: black;
				color: white;
				cursor: default;
					
				svg path{
					fill: white;
				}
			}
			
		}
		
		&.small-padding .option{
			padding: 8px 12px;
		}
	}
</style>
