import { createStore } from 'vuex'


if(typeof window.vue_locale == 'undefined'){
	var vue_locale = {
		language: 'en',
		urls: {
			en: 'https://multidot.martinelliluce.com',
			fr: 'https://multidot.martinelliluce.com/fr/',
			it: 'https://multidot.martinelliluce.com/it/'
		},
		
		customize : 'Customize your',
		select_preset : 'Select a preset to start your configuration',
		preset_text_0 : '4 cables detached<br>8 spheres<br>2700 k',
		preset_text_1 : '8 cables attached<br>12 spheres<br>2700 k',
		preset_text_2 : '12 cables attached<br>16 spheres<br>2700 k',
		preset_text_3 : '12 cables detached and attached<br>16 spheres<br>2700 k',
		mobile_overlay : 'To use the configurator you need to view the page from desktop',
		advanced : 'Enter',
		advanced_text : 'Advanced users version',
		
		back_to_preset : 'Back to preset',
		room : 'Room',
		room_height : 'Room height',
		furniture : 'Furniture',
		general_settings : 'General settings',
		cable_number : 'Cable number',
		light_color : 'Color temperature',
		select_cables : 'Select cables',
		cable : 'Cable',
		detached : 'Detached',
		attached : 'Attached',
		spheres_number : 'Spheres number',
		lights_distance : 'Spheres wheelbase',
		cable_offset : 'Cable length from canopy to first sphere',
		cable_offset_short : 'Canopy to first sphere',
		canopy_hook : 'Center canopy to rope lock',
		height : 'Height',
		viewtop : 'Top view',
		view3d : '3D view',
		proceed : 'Proceed',
		reset : 'Reset',
		save : 'Save',
		reset_confirm : 'Resetting will erase any changes, are you sure you want to proceed?',
		select_all : 'Select all',
		deselect_all : 'Deselect all',
		restricted_mode : 'Restricted mode',
		unrestricted_mode : 'Unestricted mode',
		radial : 'Radial',
		free : 'Free',
		standard : 'Standard',
		not_standard : 'Not Standard',
		
		back_to_configurator : 'Back to configurator',
		send_request : 'Send your quote request',
		form_title : 'Fill in the form to receive a quote for your configuration',
		first_name : 'First Name',
		last_name : 'Last Name',
		nation : 'Nation',
		city : 'City',
		profile : 'Profile',
		private : 'Private',
		professional : 'Professional',
		profession : 'Profession',
		mail : 'Mail',
		privacy_policy : 'Privacy policy',
		policy_agree : 'I have read and agree to the',
		generic_form_error : 'You must fill out all fields!',
		privacy_form_error : 'You must accept the privacy policy!',
		send : 'Send',
		thanks : 'Thanks!',
		thanks_text1 : 'Your request has been successfully sent.',
		thanks_text2 : 'We will contact you as soon as possible.',
		discover_multidot : 'Discover Martinelli Luce',
		configure_multidot : 'Configure Multidot',
		select : 'Select',
		load : 'Load',
		load_configuration : 'Load configuration',
		load_file : 'Load file',
		load_modal_text : 'If you have previously created or saved a Multidot composition you can reload the json file here to start again with your project.',
		
		professions : [
			'agent',
			'architect',
			'dealer',
			'manufacturer',
			'interior decorator',
			'distributor',
			'e-commerce',
			'public organisation',
			'photographer',
			'large-scale retail trade',
			'electrical wholesaler',
			'HORECA',
			'contractor',
			'engineer',
			'installer',
			'interior designer',
			'lighting designer',
			'press',
			'individual',
			'manufacturer',
			'retailer',
			'lighting retailer',
			'furniture retailer',
			'student',
			'other',
		],
		nations : [ 
		'Afghanistan',
		'Albania',
		'Algeria',
		'Andorra',
		'Angola',
		'Anguilla',
		'Antartide francese',
		'Antigua/Barbuda',
		'Antille olandesi',
		'Arabia Saudita',
		'Argentina',
		'Armenia',
		'Aruba',
		'Australia',
		'Austria',
		'Azerbaigian',
		'Bahama',
		'Bahrein',
		'Bangladesh',
		'Barbados',
		'Belgio',
		'Belize',
		'Benin',
		'Bermude',
		'Bielorussia',
		'Bolivia',
		'Bosnia-Erzegovina',
		'Botswana',
		'Brasile',
		'Brunei',
		'Bulgaria',
		'Burkina Faso',
		'Cambogia',
		'Camerun',
		'Canada',
		'Capo Verde',
		'Cayman',
		'Ceca Repubblica',
		'Centrafrica',
		'Cile',
		'Cina Rep. pop.',
		'Cina Taiwan',
		'Cipro',
		'Colombia',
		'Congo Rep. Democratica (ex Zaire )',
		'Congo Repubblica',
		'Corea Rep. (Corea del Sud)',
		'Corea Rep. pop. dem. (Corea del Nord)',
		'Costa Rica',
		'Côte d\'Ivoire',
		'Croazia',
		'Cuba',
		'Danimarca',
		'Dominicana Repubblica',
		'Egitto',
		'Emirati Arabi Uniti',
		'Equador',
		'Estonia',
		'Etiopia',
		'Faeröer isole',
		'Falkland isole',
		'Figi Isole',
		'Filippine',
		'Finlandia',
		'Francia',
		'Gabon',
		'Gambia',
		'Georgia',
		'Germania',
		'Ghana',
		'Giamaica',
		'Giappone',
		'Gibilterra',
		'Gibuti',
		'Giordania',
		'Gran Bretagna',
		'Grecia',
		'Guadalupa',
		'Guatemala',
		'Guiana',
		'Guiana francese',
		'Guinea Repubblica',
		'Haiti',
		'Honduras',
		'Hongkong',
		'India',
		'Indonesia',
		'Iran',
		'Iraq',
		'Irlanda',
		'Islanda',
		'Isole Minori (USA)',
		'Israele',
		'Italia',
		'Kazakistan',
		'Kenya',
		'Kirghizistan',
		'Kosovo (amministrato ad interim dall\'ONU)',
		'Kuwait',
		'Lettonia',
		'Libano',
		'Libia',
		'Liechtenstein',
		'Lituania',
		'Lussemburgo',
		'Macao',
		'Macedonia',
		'Madagascar',
		'Malaisia',
		'Maldive',
		'Mali',
		'Malta',
		'Marianne Isole',
		'Marocco',
		'Martinica',
		'Mauritania',
		'Mauritius',
		'Messico',
		'Moldavia',
		'Monaco',
		'Mongolia',
		'Montenegro',
		'Mozambico',
		'Myanmar (Unione)',
		'Namibia',
		'Nepal',
		'Nicaragua',
		'Niger',
		'Nigeria',
		'Norvegia',
		'Nuova Caledonia',
		'Nuova Zelanda',
		'Oman',
		'Paesi Bassi',
		'Pakistan',
		'Panama',
		'Papua-Nuova Guinea',
		'Paraguay',
		'Perù',
		'Polinesia Francese',
		'Polonia',
		'Portogallo',
		'Portorico',
		'Qatar',
		'Riunione',
		'Romania',
		'Ruanda',
		'Russia Federazione',
		'Saint Vincent e Grenadine',
		'Salvador',
		'Samoa (USA)',
		'San Cristoforo e Nevis',
		'San Marino',
		'Seicelle',
		'Senegal',
		'Serbia',
		'Singapore',
		'Siria',
		'Slovacca Repubblica',
		'Slovenia',
		'Spagna',
		'Sri Lanka',
		'Stati Uniti d\'America',
		'Sudafrica',
		'Sudan',
		'Suriname',
		'Svezia',
		'Svizzera',
		'Tagiskistan',
		'Tailandia',
		'Tanzania',
		'Togo',
		'Trinidad e Tobago',
		'Tunisia',
		'Turchia',
		'Ucraina',
		'Uganda',
		'Ungheria',
		'Uruguay',
		'Uzbekistan',
		'Venezuela',
		'Vergini Isole (britanniche)',
		'Vietnam',
		'Wallis e Futuna',
		'Yemen',
		'Zambia',
		'Zimbabwe',
		'Saint Martin',
		'Canarie',
		'Isole Marshall',
		'Antille Francesi',
		'Saint Barthelem'
	]

	};
}else{
	var vue_locale = window.vue_locale;
}

export default createStore({
	state: {
		locale : vue_locale,
		recap : false,
		configuration_data : '',
		model_file : '',
		image1 : '',
		stop_animation : false,
	},
	getters: {
		
	},
	mutations: {
		setRecap(state, value) {
			state.recap = value;
		},
		setConfigurationData(state, value) {
			state.configuration_data = value;
		},
		setModelFile(state, value) {
			state.model_file = value;
		},
		setImage1(state, value) {
			state.image1 = value;
		},
		stopAnimation(state) {
			state.stop_animation = true;
		},
	},
	actions: {
		
	},
	modules: {
		
	}
})
