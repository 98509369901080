<template>
	<div class="toggle-icon" @click="toggle">
		<Transition name="fade">
			<svg v-if="!modelValue" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M11 13.5H6C5.71667 13.5 5.47917 13.4042 5.2875 13.2125C5.09583 13.0208 5 12.7833 5 12.5C5 12.2167 5.09583 11.9792 5.2875 11.7875C5.47917 11.5958 5.71667 11.5 6 11.5H11V6.5C11 6.21667 11.0958 5.97917 11.2875 5.7875C11.4792 5.59583 11.7167 5.5 12 5.5C12.2833 5.5 12.5208 5.59583 12.7125 5.7875C12.9042 5.97917 13 6.21667 13 6.5V11.5H18C18.2833 11.5 18.5208 11.5958 18.7125 11.7875C18.9042 11.9792 19 12.2167 19 12.5C19 12.7833 18.9042 13.0208 18.7125 13.2125C18.5208 13.4042 18.2833 13.5 18 13.5H13V18.5C13 18.7833 12.9042 19.0208 12.7125 19.2125C12.5208 19.4042 12.2833 19.5 12 19.5C11.7167 19.5 11.4792 19.4042 11.2875 19.2125C11.0958 19.0208 11 18.7833 11 18.5V13.5Z" fill="black"/>
			</svg>
		</Transition>
	
		<Transition name="fade">
			<svg v-if="modelValue" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 13.5C5.71667 13.5 5.47917 13.4042 5.2875 13.2125C5.09583 13.0208 5 12.7833 5 12.5C5 12.2167 5.09583 11.9792 5.2875 11.7875C5.47917 11.5958 5.71667 11.5 6 11.5H18C18.2833 11.5 18.5208 11.5958 18.7125 11.7875C18.9042 11.9792 19 12.2167 19 12.5C19 12.7833 18.9042 13.0208 18.7125 13.2125C18.5208 13.4042 18.2833 13.5 18 13.5H6Z" fill="black"/>
			</svg>
		</Transition>
	</div>
</template>

<script>
	export default {
		name: 'ToggleIcon',
		props: ['modelValue'],
		emits: ['update:modelValue'],
		methods: {
			toggle(){
				this.$emit('update:modelValue', !this.modelValue);
			}
		}
	}
</script>

<style scoped lang="scss">
	.toggle-icon{
		position: relative;
		width: 24px;
		height: 24px;
		cursor: pointer;
		
		svg{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
</style>
