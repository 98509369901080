<template>
	<div class="input-button" :class="{active : active}">
		<div class="button" @click="enableInput">{{modelValue}} {{label}}</div>
		<input type="text" :value="modelValue" ref="input" v-on:keyup.enter="select" @blur="select">
	</div>
</template>

<script>
	export default {
		name: 'InputButton',
		props: ['modelValue', 'label', 'min', 'max'],
		emits: ['update:modelValue', 'change'],
		data: function () { return {
			active : false
		}},
		methods: {
			enableInput(){
				this.active = true;
				setTimeout( () => {
					this.$refs.input.focus();
				}, 1);
			},
			select(){
				var value = parseInt(this.$refs.input.value)
				if(isNaN(value)) value = 0;
				if(value < this.min) value = this.min;
				if(value > this.max) value = this.max;
				
				this.active = false;
				this.$emit('update:modelValue', value);
				this.$emit('change', value);
			}
		}
	}
</script>

<style scoped lang="scss">
	.input-button{
		
		input{
			display: none;
			width: 100%;
		}
		
		&.active{
			.button{
				display: none;
			}
			input{
				display: block;
			}
		}
		
		&.red{
			.button,
			input{
				border-color: #ff8469;
				color: #ff8469;
			}
		}
	}
</style>
